<template>
  <base-top-bar>
    <template
      v-slot:left
    >
      <b-nav-item to="/user-profile">
        <i
          class="icon-user text-white"
        />
      </b-nav-item>
    </template>
    {{ todaysDate }}
    <template
      v-slot:right
    >
      <b-nav-item v-if="materialCountPageOrNot" :to="/material-count-search/ + $route.params.id">
        <i
          class="icon-magnifier text-white"
        />
      </b-nav-item>
    </template>
  </base-top-bar>
</template>

<script>
import moment from 'moment'
import { builtStrings } from '@/utils/strings'
import BaseTopBar from '../../navigation/BaseTopBar'

export default {
  name: 'NotificationTopBar',
  components: { BaseTopBar },
  computed: {
    todaysDate () {
      return builtStrings.getPageStockTopBarDateMomentFormat({
        dateMoment: moment()
      })
    },
    materialCountPageOrNot () {
      // If we are on Material Count page then show the search icon on the right top corner.
      let materials = this.$store.getters['materialCount/allMaterialCountItems']
      if (materials !== undefined) {
        materials = JSON.parse(JSON.stringify(materials)) // get rid of observer so array can be empty
      }
      if (materials.length > 0) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped lang="scss">
</style>
