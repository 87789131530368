<template>
    <b-modal id="cancelModal" ok-title="Confirm" ok-only centered title="Cancel Stock Count Request" @ok="cancelStockCountRequest">
        <template v-if="!successfulSubmit">
            <div style="text-align:center; margin: auto; width:80%;">
              <p>{{ messages.getLabelCancelRequestConfirmation() }}</p>
              </div>
        </template>
        <template v-if="successfulSubmit">
          <div style="min-height:200px;" class="d-flex justify-content-center align-items-center">
            <b-icon-check2-circle scale="6" variant="success"/>
          </div>
          <div style="text-align:center; margin: auto; width:80%;">
            <h3>{{ messages.getLabelStockCountCancelled() }}</h3>
            <p>{{ messages.getLabelStockCountCancelledSuccessfully() }}</p>
          </div>
        </template>
      </b-modal>
</template>
<script>
import { messages } from '@/utils/strings'
export default {
  props: {
    id: String
  },
  data () {
    return {
      successfulSubmit: false,
      messages
    }
  },
  methods: {
    cancelStockCountRequest () {
      this.$store.dispatch('stockCount/cancelStockCount', { stockCountId: this.id })
        .then(response => {
          if (response == null || response.status !== 204) {
            this.$store.commit('stockCount/setSubmissionFailedTitle', 'Cancelling Stock Count Failed!')
            this.$bvModal.show('showSubmissionFailedModal')
          }
        })
        .catch(() => {
          this.$store.commit('stockCount/setSubmissionFailedTitle', 'Cancelling Stock Count Failed!')
          this.$bvModal.show('showSubmissionFailedModal')
        })
    }
  }
}
</script>
<style scoped>
</style>
