<template>
    <div>
      <b-modal id="showSubmissionFailedModal" centered :ok-only="true">
        <div class="d-flex justify-content-center align-items-center">
          <b-icon-exclamation-circle scale="3" variant="danger"/>
        </div>
        <p class="my-4 text-center" style="font-weight: bold; color: red">{{ submissionFailedTitle }}</p>
        <p>{{ submissionError }}</p>
      </b-modal>
    </div>
</template>
<script>
import { BIconExclamationCircle } from 'bootstrap-vue'
export default {
  name: 'SubmissionFailed',
  components: { BIconExclamationCircle },
  computed: {
    submissionFailedTitle () {
      return this.$store.getters['stockCount/submissionFailedTitle']
    },
    submissionError () {
      // eslint-disable-next-line
      return this.$store.getters['getError']
    }
  }
}
</script>
<style scoped>
</style>
