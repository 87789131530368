<template>
  <div
      class="sticky-top"
    >
      <notification-top-bar />
    </div>
</template>

<script>
/**
 * Top panel showing only in mobile view
 * This is a copy with minor modifications from Stock Management.
 * When other notifications are taken in use same structure to change to other notifications like SMS, Email etc.
 * Or consider using the same elements with different data than Stock Management is using.
 *
 * This panel contains
 *   1. Topmost row with buttons to user profile and search as well as current date.
 *   2. Dashboard panel with actions points, arrived and incoming deliveries indicators.
 *   3. Filter menu, where user can select all, arrived, incoming next and "only my orders".
 */
import NotificationTopBar from './NotificationTopBar'

export default {
  name: 'NotificationTopPanel',
  components: { NotificationTopBar }
}
</script>

<style scoped lang="scss">
.sticky-top {
  z-index: 1050;
}
</style>
