<template>
    <div>
      <b-modal id="showSubmissionSuccessfulModal" centered :ok-only="true">
        <div class="d-flex justify-content-center align-items-center">
          <b-icon-check2-circle scale="3" variant="success"/>
        </div>
        <p class="my-4 text-center" style="font-weight: bold">{{ submissionSuccessfulTitle }}</p>
        <p></p>
      </b-modal>
    </div>
</template>
<script>
import { BIconCheck2Circle } from 'bootstrap-vue'
export default {
  name: 'SubmissionSuccessful',
  components: { BIconCheck2Circle },
  computed: {
    submissionSuccessfulTitle () {
      return this.$store.getters['stockCount/submissionSuccessfulTitle']
    }
  }
}
</script>
<style scoped>
</style>
