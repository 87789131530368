<template>
  <span>
    <b-dropdown text="Order by">
      <b-dropdown-item
        v-for="(item, index) in orderByOptions"
        :key="index"
        @click="changeOrderByColumn(item.key)"
        >
        <span v-if="item.key === orderByColumn" style="color: green; text-transform: uppercase"><b>{{ item.label }}</b></span>
        <span v-else>{{ item.label }}</span>
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown text="Sort direction">
      <b-dropdown-item
        v-for="(item, index) in sortDirectionOptions"
        :key="index"
        @click="changeSortDirection(item.key)"
      >
        <span v-if="item.key === sortDirectionValue" style="color: green; text-transform: uppercase"><b>{{ item.label }}</b></span>
        <span v-else>{{ item.label }}</span>
      </b-dropdown-item>
    </b-dropdown>
  </span>
</template>
<script>
import { ASC, DESC, ASCENDING, DESCENDING } from '@/constants/stock-count'
export default {
  name: 'OrderCount',
  props: {
    orderByColumn: String,
    sortDirection: Boolean,
    sortDirectionValue: String,
    orderByOptions: Array
  },
  data () {
    return {
      sortDirectionOptions: [
        { label: ASCENDING, key: ASC },
        { label: DESCENDING, key: DESC }
      ]
    }
  },
  methods: {
    changeOrderByColumn (column) {
      this.$emit('change-order-by-column', column)
    },
    changeSortDirection (direction) {
      this.$emit('change-sort-direction', direction)
    }
  }
}
</script>
<style scoped>
</style>
